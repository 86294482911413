<template>
  <b-card no-body class="cursor-pointer wameed-card h-100 m-0">
    <b-card-header class="flex-nowrap align-items-start pb-0">
      <b-media no-body class="flex-wrap w-100">
        <b-media-body>
          <div class="d-flex align-items-start justify-content-between w-100">
            <div>
              <h5 class="text-bold-18 text-font-main mb-1 mx-2">
                {{ title }}
              </h5>
              <h5 class="text-book-14 text-font-sub mb-3 mx-2">
                {{ formatedDate(subTitle) }}
              </h5>
            </div>
            <b-badge
              v-if="submitted && statusProject == 1"
              pill
              variant="light-secondary2"
              class="text-semi-12 px-4 border-secondary2"
            >
              {{ $t('project_page.submitted') }}
            </b-badge>
            <!-- <b-badge
              v-if="!submitted && statusProject == 1"
              pill
              variant="light-warning"
              class="text-semi-12 px-4 border-warning"
            >
              {{ $t('project_page.end_in') }} {{ formatedDate(subTitle) }}
            </b-badge> -->
            <b-badge
              pill
              :variant="'light-' + statusList[1][statusProject]"
              :class="'border-' + statusList[1][statusProject]"
              class="text-reg-12 py-0 px-3"
              >{{ $t('common.' + statusList[0][statusProject]) }}</b-badge
            >
            <!-- <b-badge
              v-if="statusProject == 9"
              pill
              variant="light-danger"
              class="text-semi-12 px-4 border-danger"
            >
              {{ $t('project_page.finished') }}
            </b-badge> -->
          </div>

          <hr />
          <b-row
            class="package-details-table__body"
            style="background-color: inherit"
          >
            <b-col cols="7" class="package-details-name px-0">
              <p class="mb-0">{{ $t('form_wizard_order.job_title_required') }}:</p>
            </b-col>
            <b-col cols="5" class="package-details-value px-0">
              <p class="mb-0">
                {{ employeeJobTitle }}
              </p>
            </b-col>
            <b-col cols="7" class="package-details-name px-0">
              <p class="mb-0">{{ $t('orders_page.job_no') }}:</p>
            </b-col>
            <b-col cols="5" class="package-details-value px-0">
              <p class="mb-0">
                {{ requiredJobsNo }}
              </p>
            </b-col>
            <b-col cols="7" class="package-details-name px-0">
              <p class="mb-0">{{ $t('orders_page.sector') }}:</p>
            </b-col>
            <b-col cols="5" class="package-details-value px-0">
              <p class="mb-0">
                {{ industryPreference }}
              </p>
            </b-col>
            <b-col sm="12" class="package-details-value px-0 mt-5">
              <wameed-btn
                classes="rounded-10 text-bold-15"
                block
                :title="$t('common.request_review')"
                type="button"
                :variant="'disable'"
                @submitAction="viewDetails(id)"
              />
            </b-col>
          </b-row>
        </b-media-body>
      </b-media>
    </b-card-header>
  </b-card>
</template>

<script>
import WameedBtn from '@/components/WameedBtn.vue';

export default {
  components: { WameedBtn },
  props: {
    id: {
      type: Number,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    subTitle: {
      type: String,
      default: null,
    },
    date: {
      type: String,
      default: null,
    },
    employeeJobTitle: {
      type: String,
      default: null,
    },
    requiredJobsNo: {
      type: Number,
      default: null,
    },
    submitted: {
      type: Boolean,
      default: null,
    },
    industryPreference: {
      type: String,
      default: null,
    },
    statusProject: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      statusList: [
        {
          0: 'project_rejected',
          1: 'project_active',
          2: 'project_before_paying',
          3: 'project_under_review',
          4: 'project_offer_selection',
          5: 'project_agreed',
          6: 'project_canceled',
          7: 'project_pending',
          8: 'project_finished_employee_not_paid',
          9: 'project_finished',
          10: 'project_close_request',
          11: 'project_sent_by_employee',
          12: 'project_request_cancel',
        },
        {
          0: 'danger',
          1: 'warning',
          2: 'info',
          3: 'icon',
          4: 'success',
          5: 'warning',
          6: 'danger',
          7: 'icon',
          8: 'warning',
          9: 'success',
          10: 'icon',
          11: 'success',
          12: 'warning',
        },
      ],
    };
  },
  methods: {
    formatedDate(date) {
      if (date) {
        this.moment.locale(this.$i18n.locale === 'ar' ? 'ar_SA' : 'en_US');
        return this.moment().diff(this.moment(date), 'days') > 7
          ? this.moment(date).format('DD/MM/YYYY')
          : this.moment(date).fromNow(true);
      }
      return '------';
    },
    cardAction(id) {
      this.$emit('cardAction', id);
    },
    viewDetails(id) {
      this.$router.push({
        name: 'orders-details',
        params: { lang: this.$i18n.locale, id },
      });
    },
  },
};
</script>
