<template>
  <div class="wameed-dashboard-page-content">
    <div>
      <h-panel
        v-if="getDashboardDetails"
        :dashboard-details="getDashboardDetails"
      />
      <div class="wameed-dashboard-page-content mb-5">
        <page-header
          :title="$t('dashboard.title')"
          :sub-title="$t('dashboard.subtitle')"
          variant="white"
          :btn-title="$t('common.see_all_orders')"
          classes="text-bold-18 text-font-secondary text_decoration"
          :route-name="'orders'"
        />
        <section
          class="
            wameed-dashboard-page-content_body
            d-flex
            flex-column
            justify-content-between
          "
        >
          <div class="d-flex flex-wrap flex-1 px-0">
            <b-col
              lg="12"
              md="12"
              class="px-0"
              v-if="
                getAllOrders &&
                getAllOrders.filter((order) => order.status == 3).length < 1
              "
            >
              <wameed-no-data
                icon="nodata-icon"
                :title="$t('offers_page.no_data')"
                :sub-title="$t('offers_page.no_data_text')"
              />
            </b-col>
            <b-col
              v-for="(item, index) in getAllOrders"
              v-if="item.status == 3"
              :key="index"
              lg="3"
              md="3"
              class="py-3"
            >
              <orders-card
                :id="item.id"
                :title="item.hr_job_title"
                :sub-title="item.created_at"
                :date="item.created_at"
                :employee-job-title="item.employee_job_title"
                :required-jobs-no="item.required_jobs_no"
                :submitted="item.submitted"
                :industry-preference="item.industry_preference.name"
                :status-project="item.status"
              />
            </b-col>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import RestrictedAccount from '@/components/pages/dashboard/RestrictedAccount.vue';
import UnacceptableAccount from '@/components/pages/dashboard/UnacceptableAccount.vue';
import HPanel from '@/components/HPanel.vue';
import OrdersCard from '@/components/pages/orders/OrdersCard.vue';
import PageHeader from '@/components/PageHeader.vue';
import WameedNoData from '@/components/WameedNoData.vue';

export default {
  components: {
    RestrictedAccount,
    UnacceptableAccount,
    HPanel,
    OrdersCard,
    PageHeader,
    WameedNoData,
  },
  data() {
    return {
      filterData: {
        status: '',
        industry_preference: '',
        page: 1,
        per_page: 8,
        order_by: 'desc',
        search: '',
      },
    };
  },
  computed: {
    ...mapGetters({
      getAllOrders: 'getAllOrders',
      getTotalOrders: 'getTotalOrders',
      getProfileInfo: 'getProfileInfo',
      getDashboardDetails: 'getDashboardDetails',
    }),
  },
  mounted() {
    // console.log(this.$route.path.split("/")[2].toLowerCase());
  },

  created() {
    this.loadData();
  },
  methods: {
    ...mapActions({
      loadOrders: 'loadOrders',
      loadDashboardDetails: 'loadDashboardDetails',
    }),
    loadData() {
      this.loadOrders(this.filterData);
      this.loadDashboardDetails();
    },
  },
};
</script>
