<template>
  <div class="h-panel px-3">
    <div class="h-panel__body">
      <div>
        <h4 class="h-panel__title text-bold-33" style="line-height: inherit">
          {{ dashboardDetails.companies }}
        </h4>
        <p class="h-panel__text text-bold-16 mb-0">
          {{ $t('dashboard.company') }}
        </p>
      </div>
      <div><dashboard-compoany-icon /></div>
    </div>

    <div class="h-panel__body">
      <div>
        <h4 class="h-panel__title text-bold-33" style="line-height: inherit">
          {{ dashboardDetails.employees }}
        </h4>
        <p class="h-panel__text text-bold-16 mb-0">
          {{ $t('dashboard.employee') }}
        </p>
      </div>
      <div><dashboard-employee-icon /></div>
    </div>

    <div class="h-panel__body">
      <div>
        <h4 class="h-panel__title text-bold-33" style="line-height: inherit">
          {{ dashboardDetails.projects }}
        </h4>
        <p class="h-panel__text text-bold-16 mb-0">
          {{ $t('dashboard.job') }}
        </p>
      </div>
      <div><dashboard-job-icon /></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dashboardDetails: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>
